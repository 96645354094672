import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "bankDetailList",

  data() {
    return {
      bankDetailList: [],
      searchText: "",
      showLoader: false,
      selected: "",
      selectedId: 0,
      selectedRows: [],
      sortColumn: "bank_name",
      sortDirection: "asc",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.bankDetailList.length > 0) {
        return (
          _.where(this.bankDetailList, { isSelected: true }).length ===
          this.bankDetailList.length
        );
      }
      return false;
    },
    hasBankDetailsShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBankDetailsAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBankDetailsDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBankDetailsUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    checkUncheckedList(event) {
      // Iterate over each element in the bankDetailList array
      this.bankDetailList.forEach((element) => {
        // Set the isSelected property of each element to the value of event.target.checked
        element.isSelected = event.target.checked;
      });
    },

    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a single record is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Loop through bankDetailList and add selected records to ids array
        this.bankDetailList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      // Send a POST request to delete the records
      _vm.axios
        .post("/bank-details-deletes", { ids: ids })
        .then(function () {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },

    search(value) {
      this.getAll();
    },
    getQueryString() {
      // Construct the query string with pagination, sorting, and search parameters
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      // Append search parameter if searchText is not empty
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      // Return the final query string
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },

    applySort(columnName) {
      // Check if the selected column is already the sorted column
      if (this.sortColumn === columnName) {
        // If it is, toggle the sort direction between 'asc' and 'desc'
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        // If it's a different column, set it as the new sorted column and set the direction to 'asc'
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      // Call the getAll() function to retrieve the sorted data
      this.getAll();
    },

    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Make a GET request to retrieve bank details
      this.axios
        .get("/bank-details" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.bankDetailList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Select all rows and mark them as selected
          this.selectedRows = this.bankDetailList.map(
            (item) => "checkbox" + item.id
          );
          this.bankDetailList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Clear selected rows and mark all elements as not selected
          this.selectedRows = [];
          this.bankDetailList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the selected row to the list
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the deselected row from the list
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
