<template>
  <div class="row">
    <div class="col-md-12 text-right">
      <select class="form-control d-inline-block"
        style="height: 1.9rem; width: 4rem; margin-top: 2px; padding: 0px; padding-left: 3px;"
        v-model="selectedRecordTake" @change="updateRecordTake">
        <option v-for="option in recordTakeOptions" :key="option" :value="option">{{ option }}</option>
      </select>
      <div class="ml-2 float-right" style="margin-top: 5px;">
        <paginate style="border-color: #827af3;" :page-count="pageCount" :click-handler="clickHandler"
          active-class="active" containerClass="pagination" page-class="page-item" :first-last-button="true"
          :first-button-text="firstButtonText" :prev-text="prevButtonText" :last-button-text="lastButtonText"
          :next-text="nextButtonText" />
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';

export default {
  name: 'PaginationComponent',
  components: {
    Paginate
  },
  props: {
    pageCount: {
      type: Number,
      required: true
    },
    clickHandler: {
      type: Function,
      required: true
    },
    recordTakeOptions: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    currentRecordTake: {
      type: Number,
      required: true
    },
    lastPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedRecordTake: this.currentRecordTake
    };
  },

  computed: {
    firstButtonText() {
      if (this.currentPage === 1) {
        return '<span style="color: lightgray;">&lt;&lt;</span>';
      } else {
        return '<<';
      }
    },
    prevButtonText() {
      if (this.currentPage === 1) {
        return '<span style="color: lightgray;">&lt;</span>';
      } else {
        return '<';
      }
    },
    lastButtonText() {
      if (this.currentPage === this.lastPage) {
        return '<span style="color: lightgray;">&gt;&gt;</span>';
      } else {
        return '>>';
      }
    },
    nextButtonText() {
      if (this.currentPage === this.lastPage) {
        return '<span style="color: lightgray;">&gt;</span>';
      } else {
        return '>';
      }
    },
  },

  methods: {
    updateRecordTake() {
      this.$emit('updateRecordTake', this.selectedRecordTake);
    }
  }
};
</script>
